import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import SideMenu from '../components/organisms/Sidebar';
import Users from '../components/organisms/Users';
import routes from '../utils/routes';
import { Container, Row, Col } from 'reactstrap';
import colors from '../colors';
import Contracts from '../components/organisms/Contracts';
import Archived from '../components/organisms/Archived';
import { getContractsAction } from '../actions/contractsActions';
import { getUsersAction } from '../actions/usersActions';
import { connect } from 'react-redux';
import { StoreState } from '../utils/types';
import Home from '../components/organisms/Home';
import Contract from '../components/organisms/Contract';
import Withdraws from '../components/organisms/Withdraws';
import { getWithdrawsAction } from '../actions/withdrawsActions';

interface Props {
  getContractsAction: Function;
  getUsersAction: Function;
  getWithdrawsAction: Function;
}
interface State {}

class Dashboard extends Component<Props, State> {
  componentDidMount() {
    this.props.getContractsAction();
    this.props.getUsersAction();
    this.props.getWithdrawsAction();
  }

  render() {
    return (
      <Container fluid>
        <Row>
          <Col
            xs='2'
            style={{
              paddingLeft: 0,
              height: '100vh',
              width: '100%',
            }}>
            <SideMenu />
          </Col>
          <Col
            style={{ height: '100vh', paddingTop: 24 }}
            className='overflow-auto'>
            <Switch>
              <Route exact path={routes.home}>
                <Home />
              </Route>
              <Route path={routes.users}>
                <Users />
              </Route>
              <Route path={routes.contracts}>
                <Contracts />
              </Route>
              <Route path={routes.archived}>
                <Archived />
              </Route>
              <Route path={routes.contract}>
                <Contract />
              </Route>
              <Route path={routes.withdraws}>
                <Withdraws></Withdraws>
              </Route>
            </Switch>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = ({}: StoreState) => ({});

export default connect(mapStateToProps, {
  getContractsAction,
  getWithdrawsAction,
  getUsersAction,
})(Dashboard);
