import { defaultAction } from './../utils/types';

export type UsersState = {
  users: Array<any>;
  usersLoading: boolean;
};

export const initialState = {
  users: [],
  usersLoading: false,
};

export default (state = initialState, { type, payload }: defaultAction) => {
  switch (type) {
    case 'GET_ALL_USERS_ATTEMPT':
      return {
        ...state,
        usersLoading: true,
      };

    case 'GET_ALL_USERS_SUCCESS':
      return {
        ...state,
        users: payload,
        usersLoading: false,
      };

    case 'GET_ALL_USERS_ERROR':
      return {
        ...state,
        usersLoading: false,
      };

    default:
      return state;
  }
};
