import { toast } from 'react-toastify';
import { statusWithMoneyIn } from '../utils/constants';
import {
  getContracts,
  getTransferIn,
  getTransferOut,
  returnAmountToBuyer,
  settleTransaction,
  transferAmountToSeller,
} from '../utils/requests';
import history from '../utils/history';
import { TContract } from '../utils/types';

export const getContractsAction = () => (dispatch: Function) => {
  dispatch({ type: 'GET_ALL_CONTRACTS_ATTEMPT' });

  return getContracts()
    .then((res) => {
      dispatch({
        type: 'GET_ALL_CONTRACTS_SUCCESS',
        payload: {
          contracts: res.data.filter(
            (contract: TContract) => !contract.archived
          ),
          totalActiveContractsValue: res.data
            .filter(
              (contract: TContract) =>
                !contract.archived &&
                statusWithMoneyIn.includes(contract.status)
            )
            .reduce(
              (acc: number, contract: TContract) => acc + contract.amount,
              0
            ),
          archived: res.data.filter((contract: TContract) => contract.archived),
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: 'GET_ALL_CONTRACTS_FAIL',
      });
    });
};

export const getTransferInAction =
  (transferId: string) => (dispatch: Function) => {
    dispatch({ type: 'GET_TRANSFER_IN_ATTEMPT' });

    return getTransferIn(transferId)
      .then((res) => {
        console.log(res.data);

        dispatch({
          type: 'GET_TRANSFER_IN_SUCCESS',
          payload: {
            array: res.data,
            object: {
              [res.data.contractId]: res.data,
            },
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: 'GET_TRANSFER_IN_FAIL',
        });
      });
  };

export const getTransferOutAction =
  (transferId: string) => (dispatch: Function) => {
    dispatch({ type: 'GET_TRANSFER_OUT_ATTEMPT' });

    return getTransferOut(transferId)
      .then((res) => {
        dispatch({
          type: 'GET_TRANSFER_OUT_SUCCESS',
          payload: {
            array: res.data,
            object: { [res.data.contractId]: res.data },
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: 'GET_TRANSFER_OUT_FAIL',
        });
      });
  };

export const setDisplayedContract =
  (contract: string) => (dispatch: Function) => {
    dispatch({
      type: 'SET_DISPLAYED_CONTRACT',
      payload: contract,
    });
  };

// Should trigger returning money to the buyer - fee
export const returnAmountToBuyerAction =
  (contractId: string) => (dispatch: Function) => {
    dispatch({ type: 'RETURN_AMOUNT_TO_BUYER_ATTEMPT' });

    return returnAmountToBuyer(contractId)
      .then((res) => {
        dispatch({
          type: 'RETURN_AMOUNT_TO_BUYER_SUCCESS',
        });
      })
      .catch((error) => {
        dispatch({
          type: 'RETURN_AMOUNT_TO_BUYER_FAIL',
        });
      });
  };

export const transferAmountToSellerAction =
  (contractId: string) => (dispatch: Function) => {
    dispatch({ type: 'TRANSFER_AMOUNT_TO_SELLER_ATTEMPT' });

    return transferAmountToSeller(contractId)
      .then((res) => {
        dispatch({
          type: 'TRANSFER_AMOUNT_TO_SELLER_SUCCESS',
        });
      })
      .catch((error) => {
        dispatch({
          type: 'TRANSFER_AMOUNT_TO_SELLER_FAIL',
        });
      });
  };

export const settleTransactionAction =
  (transactionId: string) => (dispatch: Function) => {
    dispatch({ type: 'SETTLE_TRANSACTION_ATTEMPT' });

    const confirmationCode = '0000';

    return settleTransaction(transactionId, confirmationCode)
      .then((res) => {
        dispatch({
          type: 'SETTLE_TRANSACTION_SUCCESS',
        });

        toast('Transaction settled successfully', {
          type: 'success',
          autoClose: 3000,
        });
        dispatch(getContractsAction());
        history.push('/contracts');
        dispatch(setDisplayedContract('')); // Clear displayed contract
      })
      .catch((error) => {
        dispatch({
          type: 'SETTLE_TRANSACTION_FAIL',
        });

        toast('Transaction settlement failed', {
          type: 'error',
          autoClose: 3000,
        });
      });
  };
