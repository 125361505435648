export const unCamelCase = (camelCaseText: string): string => {
  return camelCaseText.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
    return str.toUpperCase();
  });
};

export const firstLetterCapitalized = (text: string): string => {
  return text ? text.charAt(0).toUpperCase() + text.slice(1) : '';
};

export const formatAmount = (amount: number): string => {
  return amount % 1 === 0
    ? amount.toString()
    : amount.toString().slice(0, amount.toString().indexOf('.') + 3);
};
