import { toast } from 'react-toastify';

export const cleanError = () => ({
  type: 'CLEAN_ERROR',
});

export const setError = (error: string) => (dispatch: Function) => {
  dispatch({
    type: 'SET_ERROR',
    payload: { error },
  });

  toast.error(error);

  setTimeout(() => {
    dispatch(cleanError());
  }, 6000);
};

export const setErrorKeyValue = (key: string, value: any) => (
  dispatch: Function
) => {
  dispatch({ type: 'SET_ERROR_KEY_VALUE', payload: { key, value } });

  if (key === 'notification' && value !== null) {
    setTimeout(() => {
      dispatch(setErrorKeyValue('notification', null));
    }, 4000);
  }
};
