import React, { ReactElement } from 'react';

interface TableTitleProps {
  title: string;
}

export default function TableTitle({
  title,
  ...props
}: TableTitleProps): ReactElement {
  return (
    <div className='py-4 mx-3'>
      <h2 style={styles.title}>{title}</h2>
    </div>
  );
}

const styles = {
  title: {
    fontFamily: 'Kufam',
    fontSize: 24,
  },
};
