export const NETWORK_ERROR = 'Error: Network Error';

export const serverUnresponsiveError =
  'Unable to connect to the server. Try again later.';

export const contractStatusLabel = {
  create: 'Created',
  agree: 'Agreed',
  accept: 'Accepted',
  reject: 'Rejected',
  complete: 'Completed',
  cancel: 'Canceled',
  service_complete: 'Service completed',
  payment_initiated: 'Payment Initiated',
  payment_rejected: 'Payment Rejected',
  payment_approved: 'Payment Approved',
};
