export default {
  signIn: '/login',
  home: '/',
  users: '/users',
  contracts: '/contracts',
  contract: '/contract/:id',
  archived: '/archived',
  transactions: '/transactions',
  withdraws: '/withdraws',
};
