import { toast } from 'react-toastify';
import { getWithdrawals, updateWithdrawal } from '../utils/requests';
import { TWithdraw } from '../utils/types';

export const getWithdrawsAction = () => (dispatch: Function) => {
  dispatch({ type: 'GET_ALL_WITHDRAWS_ATTEMPT' });

  return getWithdrawals()
    .then((res) => {
      console.log(res);
      dispatch({
        type: 'GET_ALL_WITHDRAWS_SUCCESS',
        payload: {
          withdraws: res.data,
          totalActiveWithdrawsValue: res.data.reduce(
            (acc: number, withdraw: TWithdraw) => acc + withdraw.amount,
            0
          ),
        },
      });
    })
    .catch((error: any) => {
      dispatch({
        type: 'GET_ALL_WITHDRAWS_FAIL',
      });
    });
};

export const setWithdrawsKeyValue =
  (key: string, value: any) => (dispatch: Function) => {
    dispatch({
      type: 'SET_WITHDRAWS_KEY_VALUE',
      payload: {
        key,
        value,
      },
    });
  };

export const updateWithdrawalAction =
  (id: string, data: any) => (dispatch: Function) => {
    dispatch({ type: 'UPDATE_WITHDRAWAL_ATTEMPT' });

    return updateWithdrawal(id, data)
      .then((res) => {
        dispatch({
          type: 'UPDATE_WITHDRAWAL_SUCCESS',
          payload: {
            withdraw: res.data,
          },
        });

        toast.success('Withdrawal updated successfully');
        dispatch(getWithdrawsAction());
      })
      .catch((error: any) => {
        dispatch({
          type: 'UPDATE_WITHDRAWAL_FAIL',
        });
      });
  };
