import * as React from 'react';
import { connect } from 'react-redux';
import { Spinner } from 'reactstrap';
import {
  setDisplayedContract,
  getTransferInAction,
  getTransferOutAction,
  returnAmountToBuyerAction,
  transferAmountToSellerAction,
} from '../../actions/contractsActions';
import { setLayoutKeyValue } from '../../actions/layoutActions';
import { STATUS_PAYMENT_APPROVED } from '../../utils/constants';
import { firstLetterCapitalized } from '../../utils/helpers';
import { GenericObject, StoreState } from '../../utils/types';
import TransactionCard from '../molecues/TransactionCard';

export interface ContractProps {
  displayedContract: GenericObject;
  setDisplayedContract: Function;
  transfersInByContractId: GenericObject;
  transfersOutByContractId: GenericObject;
  returnAmountToBuyerAction: Function;
  transferAmountToSellerAction: Function;
  setLayoutKeyValue: Function;
  transferAmountToSellerPrecessing: boolean;
  returnAmountToBuyerPrecessing: boolean;
}

const Contract = ({
  setLayoutKeyValue,
  displayedContract,
  setDisplayedContract,
  transfersInByContractId,
  transfersOutByContractId,
  returnAmountToBuyerAction,
  transferAmountToSellerAction,
  transferAmountToSellerPrecessing,
  returnAmountToBuyerPrecessing,
}: ContractProps) => {
  const contract = displayedContract;
  const trasfer = transfersInByContractId[contract.id];

  const goBack = () => {
    window.history.back();
    setDisplayedContract({});
  };

  return (
    <div style={{ height: '100%', width: '100%', padding: '72px' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          marginBottom: '24px',
        }}>
        <span
          onClick={() => goBack()}
          style={{
            fontSize: '24px',
            fontWeight: 'bold',
            marginRight: '24px',
            cursor: 'pointer',
          }}>
          ←
        </span>
        <h3>{contract.title}</h3>
      </div>
      <p>Who created: {firstLetterCapitalized(contract.initiator)}</p>
      <p>
        {firstLetterCapitalized(contract.initiator)} email:{' '}
        {contract.initiator === 'seller'
          ? contract.sellerEmail
          : contract.customerEmail}
      </p>
      <p>
        {contract.initiator === 'seller' ? 'Buyer' : 'Seller'} email:{' '}
        {contract.initiator === 'seller'
          ? contract.customerEmail
          : contract.sellerEmail}
      </p>

      <p>Status: {contract.status}</p>
      <p>Description: {contract.description}</p>
      <p>Amount: ${contract.amount}</p>
      {contract.inPaymentId && (
        <div>
          <h4>Transfers In</h4>
          <TransactionCard
            status={transfersInByContractId[contract.id]?.status}
            method={trasfer?.paymentMethod}
            createdAt={
              trasfer?.createdAt
                ? new Date(trasfer.createdAt).toLocaleString()
                : ''
            }
            onClick={() => {
              setLayoutKeyValue('modal', 'approveTransaction');
            }}></TransactionCard>
        </div>
      )}
      {contract.outPaymentId && (
        <div>
          <h4>Transfers Out</h4>
          {transfersOutByContractId[contract.id]?.status}
        </div>
      )}

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: '42px',
        }}>
        {contract.inPaymentId &&
          [STATUS_PAYMENT_APPROVED].includes(contract.status) && (
            <>
              <button
                onClick={() => returnAmountToBuyerAction(contract.id)}
                style={{
                  width: '240px',
                  borderRadius: '12px',
                  padding: '12px',
                  border: 'none',
                  backgroundColor: 'red',
                  color: 'white',
                  fontSize: '20px',
                  fontWeight: 'bold',
                }}>
                {returnAmountToBuyerPrecessing ? (
                  <Spinner size='sm' color='primary' type='grow' />
                ) : (
                  'Return money to the buyer'
                )}
              </button>

              <button
                onClick={() => transferAmountToSellerAction(contract.id)}
                style={{
                  width: '240px',
                  borderRadius: '12px',
                  padding: '12px',
                  border: 'none',
                  backgroundColor: 'green',
                  color: 'white',
                  fontSize: '20px',
                  fontWeight: 'bold',
                }}>
                {transferAmountToSellerPrecessing ? (
                  <Spinner size='sm' color='primary' type='grow' />
                ) : (
                  'Release money to the seller'
                )}
              </button>
            </>
          )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ contracts }: StoreState) => ({
  displayedContract: contracts.displayedContract,
  transfersInByContractId: contracts.transfersInByContractId,
  transfersOutByContractId: contracts.transfersOutByContractId,
  transferAmountToSellerPrecessing: contracts.transferAmountToSellerPrecessing,
  returnAmountToBuyerPrecessing: contracts.returnAmountToBuyerPrecessing,
});

export default connect(mapStateToProps, {
  returnAmountToBuyerAction,
  transferAmountToSellerAction,
  setDisplayedContract,
  getTransferInAction,
  getTransferOutAction,
  setLayoutKeyValue,
})(Contract);
