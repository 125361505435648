import { defaultAction } from './../utils/types';

export type ErrorState = {
  error: string;
};

export const initialState = {
  error: '',
};

const errorReducer = (
  state = initialState,
  { type, payload }: defaultAction
) => {
  switch (type) {
    case 'SET_ERROR':
      return {
        ...state,
        error: payload.error,
      };

    case 'CLEAN_ERROR':
      return {
        ...state,
        error: '',
      };

    default:
      return state;
  }
};

export default errorReducer;
