import * as React from 'react';
import Dashboard from '../screens/Dashboard';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Router } from 'react-router';
import history from '../utils/history';
import SignIn from '../screens/SignIn';
import { connect } from 'react-redux';
import { StoreState } from '../utils/types';
import routes from '../utils/routes';
import { recoverSessionAction } from '../actions/sessionActions';
import { useEffect } from 'react';
import ApproveTransactionModal from './organisms/ApproveTransactionModal';
import { setLayoutKeyValue } from '../actions/layoutActions';
import { settleTransactionAction } from '../actions/contractsActions';
import { updateWithdrawalAction } from '../actions/withdrawsActions';
import ApproveWithdrawalModal from './organisms/ApproveWithdrawalModal';

export interface RouterComponentProps {
  isAuthenticated: boolean;
  recoverSessionAction: Function;
  modal: string;
  setLayoutKeyValue: Function;
  displayedContract: any;
  displayedWithdrawal: any;
  updateWithdrawalAction: Function;
  transfersInByContractId: any;
  settleTransactionAction: Function;
}

const RouterComponent = ({
  isAuthenticated,
  recoverSessionAction,
  displayedContract,
  displayedWithdrawal,
  transfersInByContractId,
  ...props
}: RouterComponentProps) => {
  useEffect(() => {
    recoverSessionAction().then(() => history.push(routes.home));
  }, [recoverSessionAction]);

  return (
    <Router history={history}>
      <Switch>
        <Route path={routes.signIn}>
          <SignIn />
        </Route>
        <Route path={routes.home}>
          {isAuthenticated ? (
            <Dashboard />
          ) : (
            <Redirect push to={routes.signIn} />
          )}
        </Route>
      </Switch>
      <>
        {props.modal === 'approveTransaction' && (
          <ApproveTransactionModal
            contract={displayedContract}
            transaction={transfersInByContractId[displayedContract.id]}
            onApprove={() => {
              props.setLayoutKeyValue('modal', '');
              props.settleTransactionAction(displayedContract.inPaymentId);
            }}
            onClose={() => props.setLayoutKeyValue('modal', '')}
          />
        )}
        {props.modal === 'withdrawalConfirm' && (
          <ApproveWithdrawalModal
            withdrawal={displayedWithdrawal}
            onApprove={() => {
              props.setLayoutKeyValue('modal', '');
              props.updateWithdrawalAction(displayedWithdrawal._id, {
                status: 'sent',
                withdrawId: displayedWithdrawal._id,
              });
            }}
            onClose={() =>
              props.setLayoutKeyValue('modal', '')
            }></ApproveWithdrawalModal>
        )}
      </>
    </Router>
  );
};

const mapStateToProps = ({
  session: { userId },
  layout,
  contracts,
  withdraws,
}: StoreState) => ({
  isAuthenticated: Boolean(userId),
  displayedWithdrawal: withdraws.displayedWithdrawal,
  displayedContract: contracts.displayedContract,
  transfersInByContractId: contracts.transfersInByContractId,
  modal: layout.modal,
});

export default connect(mapStateToProps, {
  recoverSessionAction,
  setLayoutKeyValue,
  settleTransactionAction,
  updateWithdrawalAction,
})(RouterComponent);
