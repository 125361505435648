import { defaultAction } from './../utils/types';

export type LayoutState = {
  modal: string;
};

export const initialState = {
  modal: '',
};

const layoutReducer = (
  state = initialState,
  { type, payload }: defaultAction
) => {
  switch (type) {
    case 'SET_LAYOUT_KEY_VALUE':
      return {
        ...state,
        [payload.key]: payload.value,
      };

    default:
      return state;
  }
};

export default layoutReducer;
