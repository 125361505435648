import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import history from '../utils/history';
import { Button, Col, Container, Form, Input, Row, Spinner } from 'reactstrap';
import { StoreState } from '../utils/types';
import { setAuthValue } from '../actions/authActions';
import { signInInitAction, signIn2FAAction } from '../actions/sessionActions';
import routes from '../utils/routes';
import Logo from '../components/atoms/Logo';

interface SignInProps {
  signInInitAction: Function;
  signIn2FAAction: Function;
  signInStep: number;
  setAuthValue: Function;
  email: string;
  password: string;
  authenticating: boolean;
  signInToken: string | null;
}

const SignIn = (props: SignInProps) => {
  const signInInit = (e: any) => {
    e.preventDefault();
    props.signInInitAction(props.email, props.password);
  };

  const signInConfirm = (e: any) => {
    e.preventDefault();
    props.signIn2FAAction(props.signInToken, props.email).then(() => {
      history.push(routes.home);
    });
  };

  const signInInitialForm = () => {
    return (
      <Form size='large'>
        <Input
          value={props.email}
          onChange={(event) => props.setAuthValue('email', event.target.value)}
          className='mb-4'
          type='email'
          name='email'
          id='signInEmail'
          placeholder='E-mail address'
        />
        <Input
          value={props.password}
          onChange={(event) =>
            props.setAuthValue('password', event.target.value)
          }
          className='mb-4'
          type='password'
          name='password'
          id='signInPassword'
          placeholder='Password'
        />

        <Button onClick={signInInit} color='secondary' size='large'>
          Sign In
        </Button>
      </Form>
    );
  };

  const signInTokenForm = () => {
    return (
      <Form size='large'>
        <Input
          className='mb-4'
          type='number'
          name='token'
          id='signInToken'
          onChange={(event) =>
            props.setAuthValue('signInToken', event.target.value)
          }
          placeholder='6 digit code'
        />

        <Button onClick={signInConfirm} color='secondary' size='large'>
          Confirm
        </Button>
      </Form>
    );
  };

  return (
    <Container>
      <Row style={{ height: '100vh', display: 'flex', alignItems: 'center' }}>
        <Col xs='3'></Col>
        <Col xs='6'>
          <Logo></Logo>
          {/* <h1 className='font-kufam text-medium mb-4'>Quidlock</h1> */}
          {props.authenticating ? (
            <Spinner size='lg' color='secondary' type='grow' />
          ) : (
            <Fragment>
              <h2 className='font-kufam mb-4' color='black'>
                {props.signInStep === 0
                  ? 'Sign In to admin account'
                  : 'Confirm admin account sign in'}
              </h2>
              {props.signInStep === 0 ? signInInitialForm() : signInTokenForm()}
            </Fragment>
          )}
        </Col>
        <Col xs='3'></Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = ({ auth, session }: StoreState) => ({
  signInStep: auth.signInStep,
  password: auth.password,
  email: auth.email,
  signInToken: auth.signInToken,
  authenticating: session.authenticating,
});

export default connect(mapStateToProps, {
  setAuthValue,
  signInInitAction,
  signIn2FAAction,
})(SignIn);
