export const statusWithTransfer = [
  'payment_initiated',
  'payment_rejected',
  'payment_approved',
  'service_complete',
  'accept',
  'reject',
  'complete',
  'cancel',
];

export const statusWithMoneyIn = [
  'payment_initiated',
  'payment_approved',
  'service_complete',
  'accept',
  'reject',
];

export const hiddenColumns = [
  '_id',
  'id',
  '__v',
  'userId',
  'inPaymentId',
  'outPaymentId',
  'archived',
];

//create	A new transaction has been created.
export const STATUS_CONTRACT_CREATED = 'create';

//agree	All parties have agreed to the transaction.
export const STATUS_CONTRACT_AGREED = 'agree';

export const STATUS_PAYMENT_INITIATED = 'payment_initiated';

//payment_rejected	payment processor has rejected the payment for the transaction.
export const STATUS_PAYMENT_REJECTED = 'payment_rejected';

//payment_approved	payment processor has approved the payment for the transaction and the goods may now be shipped by the seller.
// Transaction funded (money are locked)
export const STATUS_PAYMENT_APPROVED = 'payment_approved';

//service_complete	The seller has indicated that the service have been provided.
export const STATUS_SERVICE_COMPLETED = 'service_complete';

//accept	The buyer has indicated that the goods have been accepted.
export const STATUS_ACCEPTED = 'accept';

//reject	The buyer has indicated that the goods have been rejected.
export const STATUS_REJECTED = 'reject';

//complete	All disbursements have been made to the contractor, closing statements have been sent. Participants marked the transaction as complete.
export const STATUS_COMPLETED = 'complete';

//cancel participant has marked the payment as cancelled.
export const STATUS_CANCELLED = 'cancel';

export const paymentStatus = {
  INITIATED: 'initiated',
  COMPLETED: 'completed',
  FAILED: 'failed',
};
