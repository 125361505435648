import { defaultAction, GenericObject, TContract } from '../utils/types';

export type ContractsState = {
  contracts: Array<TContract>;
  archived: Array<TContract>;
  contractsLoading: boolean;
  totalActiveContractsValue: number;
  displayedContract: GenericObject;
  transfersInByContractId: GenericObject;
  transfersOutByContractId: GenericObject;
  transferAmountToSellerPrecessing: boolean;
  transferAmountToSellerSuccess: boolean;
  returnAmountToBuyerPrecessing: boolean;
  returnAmountToBuyerSuccess: boolean;
};

export const initialState = {
  contracts: [],
  contractsLoading: false,
  archived: [],
  transfersIn: [],
  transfersOut: [],
  totalActiveContractsValue: 0,
  displayedContract: {},
  transfersInByContractId: {},
  transfersOutByContractId: {},
  transferAmountToSellerPrecessing: false,
  transferAmountToSellerSuccess: false,
  returnAmountToBuyerPrecessing: false,
  returnAmountToBuyerSuccess: false,
};

export default (state = initialState, { type, payload }: defaultAction) => {
  switch (type) {
    case 'GET_ALL_CONTRACTS_ATTEMPT':
      return {
        ...state,
        contractsLoading: true,
      };

    case 'GET_ALL_CONTRACTS_SUCCESS':
      return {
        ...state,
        contracts: payload.contracts,
        archived: payload.archived,
        contractsLoading: false,
        totalActiveContractsValue: payload.totalActiveContractsValue,
      };

    case 'GET_ALL_CONTRACTS_ERROR':
      return {
        ...state,
        contractsLoading: false,
      };

    case 'GET_TRANSFER_IN_SUCCESS':
      return {
        ...state,
        transfersIn: [...state.transfersIn, payload.array],
        transfersInByContractId: {
          ...state.transfersInByContractId,
          ...payload.object,
        },
      };

    case 'GET_TRANSFER_OUT_SUCCESS':
      return {
        ...state,
        transfersOut: [...state.transfersOut, payload.array],
        transfersOutByContractId: {
          ...state.transfersOutByContractId,
          ...payload.object,
        },
      };

    case 'SET_DISPLAYED_CONTRACT':
      return {
        ...state,
        displayedContract: payload,
      };

    case 'TRANSFER_AMOUNT_TO_SELLER_ATTEMPT':
      return {
        ...state,
        transferAmountToSellerPrecessing: true,
        transferAmountToSellerSuccess: false,
      };

    case 'TRANSFER_AMOUNT_TO_SELLER_SUCCESS':
      return {
        ...state,
        transferAmountToSellerPrecessing: false,
        transferAmountToSellerSuccess: true,
      };

    case 'TRANSFER_AMOUNT_TO_SELLER_FAIL':
      return {
        ...state,
        transferAmountToSellerPrecessing: false,
        transferAmountToSellerSuccess: false,
      };

    case 'RETURN_AMOUNT_TO_BUYER_ATTEMPT':
      return {
        ...state,
        returnAmountToBuyerPrecessing: true,
        returnAmountToBuyerSuccess: false,
      };

    case 'RETURN_AMOUNT_TO_BUYER_SUCCESS':
      return {
        ...state,
        returnAmountToBuyerPrecessing: false,
        returnAmountToBuyerSuccess: true,
      };

    case 'RETURN_AMOUNT_TO_BUYER_FAIL':
      return {
        ...state,
        returnAmountToBuyerPrecessing: false,
        returnAmountToBuyerSuccess: false,
      };

    default:
      return state;
  }
};
