import axios from 'axios';
import { apiUrl } from '../config';
import store from '../store';
import { NETWORK_ERROR, serverUnresponsiveError } from './texts';
import { setError } from '../actions/errorActions';
import { ApiResponse } from './types';
import { logoutAction } from '../actions/sessionActions';
axios.defaults.withCredentials = true;

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    console.log(error.message);
    console.log(error?.response?.data?.error);
    if (!error.response || [NETWORK_ERROR].includes(error.message)) {
      store.dispatch(setError(serverUnresponsiveError));
    } else if (
      error?.response?.data?.error === 'Unauthenticated' &&
      store.getState().session.userId
    ) {
      console.log('should logout');

      // store.dispatch(logoutAction());
      // store.dispatch(setError(error.response.data.error));
    } else if (error?.response?.data?.error) {
      store.dispatch(setError(error.response.data.error));
    }

    return Promise.reject(error);
  }
);

//AUTH

export const signInInit = async function (
  email: string,
  password: string
): Promise<ApiResponse> {
  return await axios.post(apiUrl + '/auth/signInInit', {
    email,
    password,
  });
};

export const signIn2FA = async function (
  token: string,
  email: string
): Promise<ApiResponse> {
  return await axios.post(apiUrl + '/auth/signIn2FA', {
    token,
    email,
  });
};

export const recoverSession = async function (): Promise<ApiResponse> {
  return await axios.get(apiUrl + '/auth/recoverSession');
};

export const logout = async function (): Promise<ApiResponse> {
  return await axios.get(apiUrl + '/auth/logout');
};

//USERS

export const getUsers = async function (): Promise<ApiResponse> {
  return await axios.get(apiUrl + '/users/getAll');
};

//CONTRACTS

export const getContracts = async function (): Promise<ApiResponse> {
  return await axios.get(apiUrl + '/contracts/getAll');
};

//TRANSFERS

export const getTransferIn = async function (id: string): Promise<ApiResponse> {
  return await axios.get(apiUrl + `/transfers/getIn/${id}`);
};

export const getTransferOut = async function (
  id: string
): Promise<ApiResponse> {
  return await axios.get(apiUrl + `/transfers/getOut/${id}`);
};

export const returnAmountToBuyer = async function (
  contractId: string
): Promise<ApiResponse> {
  return await axios.post(apiUrl + '/contracts/returnAmountToBuyer', {
    contractId,
  });
};

export const transferAmountToSeller = async function (
  contractId: string
): Promise<ApiResponse> {
  return await axios.post(apiUrl + '/contracts/transferAmountToSeller', {
    contractId,
  });
};

export const settleTransaction = async (
  transactionId: any,
  confirmationCode: string
) => {
  const { data } = await axios.post(
    `${apiUrl}/transfers/settleIn/${transactionId}`,
    {
      confirmationCode,
    }
  );
  return data;
};

//WITHDRAWALS

export const getWithdrawals = async function (): Promise<ApiResponse> {
  return await axios.get(apiUrl + '/withdrawals/getAll');
};

export const updateWithdrawal = async (id: string, updatedObject: any) => {
  const { data } = await axios.post(
    `${apiUrl}/withdrawals/updateById`,
    updatedObject
  );

  return data;
};
