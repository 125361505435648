import React from 'react';
import { paymentStatus } from '../../utils/constants';
import { firstLetterCapitalized } from '../../utils/helpers';

interface TransactionCardProps {
  status: string;
  method: string;
  createdAt: string;
  onClick: () => void;
}

const TransactionCard: React.FC<TransactionCardProps> = ({
  status,
  method,
  createdAt,
  onClick,
}) => {
  return (
    <>
      <div className='transaction-card'>
        <div className='transaction-card__info'>
          <span className='transaction-card__status'>
            {firstLetterCapitalized(status)}
          </span>
          <span className='transaction-card__method'>
            {firstLetterCapitalized(method)}
          </span>
          <span className='transaction-card__created-at'>{createdAt}</span>
        </div>
        {method === 'zelle' && status === paymentStatus.INITIATED && (
          <div className='transaction-card__action'>
            <button
              className='transaction-card__action-button'
              onClick={onClick}>
              Set as completed
            </button>
          </div>
        )}
      </div>
      <style>{`
        .transaction-card {
          display: flex;
          align-items: center;
          padding: 16px;
          border-radius: 8px;
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
          margin-bottom: 16px;
          background-color: rgba(0, 0, 0, 0.06);
        }

        .transaction-card__info {
          flex: 1;
          margin-right: 36px;
        }

        .transaction-card__status {
          font-size: 16px;
          font-weight: bold;
          color: #3f51b5;
          margin-right: 36px;
        }

        .transaction-card__method {
          font-size: 16px;
          font-weight: bold;
          color: #333;
          margin-top: 8px;
          margin-right: 36px;
        }

        .transaction-card__created-at {
          font-size: 12px;
          color: #999;
          margin-top: 8px;
        }

        .transaction-card__action-button {
          padding: 8px 16px;
          border-radius: 4px;
          border: none;
          background-color: var(--primary);
          color: white;
          font-size: 14px;
          font-weight: bold;
          cursor: pointer;
          transition: background-color 0.2s ease-in-out;
        }

        .transaction-card__action-button:hover {
          background-color: #1a237e;
        }
      `}</style>
    </>
  );
};

export default TransactionCard;
