import * as React from 'react';
import colors from '../../colors';

export interface LogoProps {}

export default function Logo(props: LogoProps) {
  return (
    <span
      style={{
        display: 'block',
        paddingBottom: 16,
      }}>
      <svg
        width='172'
        height='25'
        viewBox='0 0 172 25'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M23.5169 17.8087H24.9947C25.4404 17.8087 25.6164 17.9823 25.6164 18.4221V23.4794C25.6164 23.9191 25.4404 24.0927 24.9947 24.0927H18.1215C16.2683 24.0927 13.5471 24.4283 12.0341 24.4283C5.09043 24.4283 0 19.3016 0 12.219C0 5.12493 5.09043 0.00976562 12.0341 0.00976562C18.9777 0.00976562 24.0681 5.10179 24.0681 12.1843C24.0681 13.874 23.5169 15.5404 23.0008 16.9639C22.7897 17.531 22.9656 17.8087 23.5169 17.8087ZM6.15778 12.1728C6.15778 15.4594 8.66781 18.2138 12.0341 18.2138C15.4003 18.2138 17.9103 15.5057 17.9103 12.2075C17.9103 8.92081 15.4003 6.2012 12.0341 6.2012C8.66781 6.2012 6.15778 8.88609 6.15778 12.1728Z'
          fill='#1D1D1B'
        />
        <path
          d='M27.4023 13.7711V1.12205C27.4023 0.578133 27.6135 0.34668 28.153 0.34668H32.7625C33.3138 0.34668 33.4898 0.589706 33.4898 1.12205V13.1346C33.4898 16.456 35.038 17.9142 37.6536 17.9142C40.234 17.9142 41.7822 16.456 41.7822 13.1346V1.12205C41.7822 0.578133 41.9934 0.34668 42.5446 0.34668H47.0838C47.6351 0.34668 47.8345 0.589706 47.8696 1.12205V13.7711C47.8696 20.9925 44.2923 24.4181 37.6184 24.4181C30.9445 24.4181 27.4023 20.9925 27.4023 13.7711Z'
          fill='#1D1D1B'
        />
        <path
          d='M57.4862 23.3418C57.4862 23.8857 57.2751 24.0825 56.7238 24.0825H51.7741C51.2228 24.0825 51.0234 23.8742 51.0234 23.3418V1.08734C51.0234 0.543416 51.2346 0.34668 51.7741 0.34668H56.7238C57.2751 0.34668 57.4862 0.554989 57.4862 1.08734V23.3418Z'
          fill='#1D1D1B'
        />
        <path
          d='M69.6972 0.336914C76.5353 0.336914 81.2152 5.29006 81.2152 12.1759C81.2152 19.0617 76.5353 24.0843 69.6972 24.0843H61.6862C61.1349 24.0843 60.9355 23.8413 60.9355 23.3089V1.12386C60.9355 0.579943 61.1467 0.348489 61.6862 0.348489H69.6972V0.336914ZM66.8353 16.967C66.8353 17.5109 67.0464 17.7424 67.5977 17.7424H69.0755C72.7233 17.7424 74.9166 15.6014 74.9166 12.2106C74.9166 8.81977 72.7116 6.6788 69.0755 6.6788H67.5977C67.0464 6.6788 66.8353 6.92183 66.8353 7.45418V16.967Z'
          fill='#1D1D1B'
        />
        <path
          d='M89.6032 16.9323C89.6032 17.4762 89.8143 17.7077 90.3538 17.7077H99.1859C99.7371 17.7077 99.9365 17.9507 99.9365 18.483V23.2973C99.9365 23.8412 99.7254 24.0727 99.1859 24.0727H84.1022C83.551 24.0727 83.3516 23.8297 83.3516 23.2973V1.11229C83.3516 0.568368 83.5627 0.336914 84.1022 0.336914H88.8408C89.3921 0.336914 89.6032 0.57994 89.6032 1.11229V16.9323Z'
          fill='#1D1D1B'
        />
        <path
          d='M100.496 12.2093C100.496 5.11518 105.587 0 112.53 0C119.474 0 124.564 5.09202 124.564 12.1746C124.564 19.2687 119.474 24.4186 112.53 24.4186C105.587 24.4186 100.496 19.3034 100.496 12.2093ZM106.795 12.1746C106.795 15.3571 109.164 17.9725 112.542 17.9725C115.908 17.9725 118.277 15.3918 118.277 12.2093C118.277 9.0152 115.908 6.44604 112.542 6.44604C109.164 6.44604 106.795 8.99205 106.795 12.1746Z'
          fill='#1D1D1B'
        />
        <path
          d='M137.841 17.9823C140.081 17.9823 141.653 17.0681 142.896 15.3669C143.271 14.8577 143.553 14.7882 144.139 15.066L148.057 16.8945C148.749 17.2301 148.643 17.7393 148.362 18.3179C146.473 22.2179 142.38 24.4283 137.806 24.4283C130.862 24.4283 125.807 19.3016 125.807 12.219C125.807 5.12493 130.862 0.00976562 137.806 0.00976562C142.38 0.00976562 146.473 2.22017 148.362 6.12019C148.643 6.69883 148.737 7.20803 148.057 7.54364L144.139 9.37214C143.553 9.63832 143.283 9.58046 142.896 9.07126C141.653 7.37006 140.081 6.45581 137.841 6.45581C134.545 6.45581 132.093 9.00181 132.093 12.219C132.093 15.4363 134.533 17.9823 137.841 17.9823Z'
          fill='#1D1D1B'
        />
        <path
          d='M171.841 23.0988C172.146 23.5385 172.017 24.0825 171.36 24.0825H165.965C165.343 24.0825 164.897 23.9436 164.381 23.2724L159.15 16.0509C158.845 15.6112 158.423 15.6112 158.083 15.9815L157.391 16.7337C156.945 17.2082 156.875 17.4513 156.875 18.053V23.3071C156.875 23.851 156.663 24.0825 156.112 24.0825H151.432C150.881 24.0825 150.682 23.8394 150.682 23.3071V1.12205C150.682 0.578133 150.893 0.34668 151.432 0.34668H156.112C156.663 0.34668 156.875 0.589706 156.875 1.12205V7.49866C156.875 8.0773 157.391 8.14674 157.766 7.74169L163.537 1.26093C164.158 0.578136 164.604 0.34668 165.284 0.34668H171.337C171.958 0.34668 172.158 0.85588 171.747 1.33036L163.806 10.1488C163.466 10.5192 163.29 10.7622 163.29 10.9936C163.29 11.2251 163.431 11.4681 163.631 11.7343L171.841 23.0988Z'
          fill='#1D1D1B'
        />
      </svg>
    </span>
  );
}
