import * as React from 'react';
import { connect } from 'react-redux';
import colors from '../../colors';
import { formatAmount } from '../../utils/helpers';
import { StoreState } from '../../utils/types';
import Overlay from './Overlay';

export interface ApproveWithdrawalModalProps {
  withdrawal: any;
  users: any;
  onClose: () => void;
  onApprove: () => void;
}

function ApproveWithdrawalModal(props: ApproveWithdrawalModalProps) {
  return (
    <Overlay onClose={() => props.onClose()}>
      <>
        <div className='approve-withdrawal-modal'>
          <div className='approve-withdrawal-modal__title'>
            Confirm that money has been sent to this user
          </div>
          <div className='approve-withdrawal-modal__content'>
            Are you sure you want to mark this withdrawal as complete?
            <br />
            <br />
            <div className='approve-withdrawal-modal__content__details'>
              <div className='approve-withdrawal-modal__content__details__label'>
                Amount
              </div>
              <div className='approve-withdrawal-modal__content__details__value'>
                ${formatAmount(props.withdrawal.amount)}
              </div>
            </div>
            <div className='approve-withdrawal-modal__content__details'>
              <div className='approve-withdrawal-modal__content__details__label'>
                From
              </div>
              <div className='approve-withdrawal-modal__content__details__value'>
                Quidlock
              </div>
            </div>
            <div className='approve-withdrawal-modal__content__details'>
              <div className='approve-withdrawal-modal__content__details__label'>
                To
              </div>
              <div className='approve-withdrawal-modal__content__details__value'>
                {
                  props.users?.find(
                    (user: any) => user.id === props.withdrawal.userId
                  )?.email
                }
              </div>
            </div>
            <div className='approve-withdrawal-modal__content__details'>
              <div className='approve-withdrawal-modal__content__details__label'>
                Created at
              </div>
              <div className='approve-withdrawal-modal__content__details__value'>
                {props.withdrawal?.createdAt
                  ? new Date(props.withdrawal.createdAt).toLocaleString()
                  : ''}
              </div>
            </div>
            <div className='approve-withdrawal-modal__content__details'>
              <div className='approve-withdrawal-modal__content__details__label'>
                Status
              </div>
              <div className='approve-withdrawal-modal__content__details__value'>
                {props.withdrawal.status}
              </div>
            </div>
            <div className='approve-withdrawal-modal__content__details'>
              <div className='approve-withdrawal-modal__content__details__label'>
                Method
              </div>
              <div className='approve-withdrawal-modal__content__details__value'>
                {props.withdrawal.method}
              </div>
            </div>
            <div className='approve-withdrawal-modal__content__buttons'>
              <button
                className='approve-withdrawal-modal__content__buttons__button'
                onClick={() => props.onClose()}
                style={{ marginRight: '16px' }}>
                Cancel
              </button>
              <button
                className='approve-withdrawal-modal__content__buttons__button'
                style={{ backgroundColor: colors.primary }}
                onClick={() => props.onApprove()}>
                Confirm
              </button>
            </div>
          </div>
        </div>

        <style>{`
        .approve-withdrawal-modal {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
        .approve-withdrawal-modal__title {
          font-size: 24px;
          font-weight: bold;
          color: #333;
          margin-bottom: 16px;
        }
        .approve-withdrawal-modal__content {
          font-size: 16px;
          color: #333;
          margin-bottom: 16px;
        }
        .approve-withdrawal-modal__content__buttons {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 16px;
        }
        .approve-withdrawal-modal__content__buttons__button {
          padding: 8px 16px;
          border-radius: 5px;
          border: none;
          background-color: #333;
          color: white;
          font-size: 16px;
          cursor: pointer;
        }

        .approve-withdrawal-modal__content__details {
          display: flex;
          justify-content: space-between;
          margin-top: 8px;
        }
        .approve-withdrawal-modal__content__details__label {
          font-weight: bold;
        }
        .approve-withdrawal-modal__content__details__value {
          color: #333;
        }

      `}</style>
      </>
    </Overlay>
  );
}

//add redux
const mapStateToProps = ({ users }: StoreState) => ({
  users: users.users,
});

export default connect(mapStateToProps, {})(ApproveWithdrawalModal);
