import { composeWithDevTools } from 'redux-devtools-extension';
import { createStore, applyMiddleware } from 'redux';
import thunk, { ThunkDispatch } from 'redux-thunk';
import { defaultAction } from './utils/types';
import appReducer from './reducers';

const rootReducer = (state: any, action: defaultAction) => {
  if (action.type === 'RESET_APP_STATE') {
    state = undefined;
  }
  return appReducer(state, action);
};

const initialState = {};

const store = createStore(
  rootReducer,
  initialState,
  composeWithDevTools(applyMiddleware<ThunkDispatch<any, any, any>, any>(thunk))
);

export default store;
