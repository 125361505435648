import * as React from 'react';

export interface StatsCardProps {
  title: string;
  value: string;
}

export default function StatsCard({ title, value }: StatsCardProps) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        border: '2px solid black',
        borderRadius: '12px',
        paddingTop: '24px',
        paddingBottom: '24px',
        paddingLeft: '24px',
      }}>
      <h4>{title}</h4>
      <h5
        style={{
          fontSize: '2rem',
        }}>
        {value}
      </h5>
    </div>
  );
}
