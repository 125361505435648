import { combineReducers } from 'redux';
import authReducer from './authReducer';
import usersReducer from './usersReducer';
import sessionReducer from './sessionReducer';
import errorReducer from './errorReducer';
import contractsReducer from './contractsReducer';
import layoutReducer from './layoutReducer';
import withdrawsReducer from './withdrawsReducer';

export default combineReducers({
  auth: authReducer,
  users: usersReducer,
  contracts: contractsReducer,
  withdraws: withdrawsReducer,
  session: sessionReducer,
  error: errorReducer,
  layout: layoutReducer,
});
