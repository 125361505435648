import { defaultAction, GenericObject, TWithdraw } from '../utils/types';

export type WithdrawsState = {
  withdraws: Array<TWithdraw>;
  withdrawsLoading: boolean;
  totalWithdrawedValue: number;
  displayedWithdrawal: GenericObject;
};

export const initialState = {
  withdraws: [],
  withdrawsLoading: false,
  totalWithdrawsValue: 0,
  displayedWithdrawal: {},
};

export default (state = initialState, { type, payload }: defaultAction) => {
  switch (type) {
    case 'GET_ALL_WITHDRAWS_ATTEMPT':
      return {
        ...state,
        withdrawsLoading: true,
      };

    case 'GET_ALL_WITHDRAWS_SUCCESS':
      return {
        ...state,
        withdraws: payload.withdraws,
        withdrawsLoading: false,
        totalActiveWithdrawsValue: payload.totalActiveWithdrawsValue,
      };

    case 'GET_ALL_WITHDRAWS_FAIL':
      return {
        ...state,
        withdrawsLoading: false,
      };

    case 'SET_DISPLAYED_WITHDRAW':
      return {
        ...state,
        displayedWithdrawal: payload,
      };

    case 'SET_WITHDRAWS_KEY_VALUE':
      return {
        ...state,
        [payload.key]: payload.value,
      };

    default:
      return state;
  }
};
