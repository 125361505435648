import { getUsers } from '../utils/requests';

export const getUsersAction = () => (dispatch: Function) => {
  dispatch({ type: 'GET_ALL_USERS_ATTEMPT' });

  return getUsers()
    .then((res) => {
      dispatch({
        type: 'GET_ALL_USERS_SUCCESS',
        payload: res.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: 'GET_ALL_USERS_FAIL',
      });
    });
};
