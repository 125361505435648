import { defaultAction } from './../utils/types';
// import {
//   setAuthToken,
//   clearAuthToken,
//   saveUserCredentialsInSaveStorage,
// } from './../utils/auth';

export type AuthState = {
  readonly signInToken: string | null;
  readonly isAuthenticated: boolean;
  readonly email: string;
  readonly emailError: string;
  readonly password: string;
  readonly passwordError: string;
  readonly confirmPassword: string;
  readonly confirmPasswordError: string;
  readonly signInErrorMessage: string;
  readonly isAuthenticating: boolean;
  readonly signInStep: number;
};

const initialState: AuthState = {
  signInToken: null,
  isAuthenticated: true,
  email: '',
  emailError: '',
  password: '',
  passwordError: '',
  confirmPassword: '',
  confirmPasswordError: '',
  signInErrorMessage: '',
  isAuthenticating: false,
  signInStep: 0,
};

export default (state = initialState, { type, payload }: defaultAction) => {
  switch (type) {
    // case SET_EMAIL: {
    //   return {
    //     ...state,
    //     ...payload
    //   };
    // }

    // case SET_EMAIL_ERROR: {
    //   return {
    //     ...state,
    //     emailError: payload
    //   };
    // }

    // case SET_PASSWORD: {
    //   return {
    //     ...state,
    //     ...payload
    //   };
    // }

    // case SET_PASSWORD_ERROR: {
    //   return {
    //     ...state,
    //     passwordError: payload
    //   };
    // }

    // case SET_CONFIRM_PASSWORD: {
    //   return {
    //     ...state,
    //     ...payload
    //   };
    // }

    // case SET_CONFIRM_PASSWORD_ERROR: {
    //   return {
    //     ...state,
    //     confirmPasswordError: payload
    //   };
    // }

    case 'SET_AUTH_VALUE': {
      return {
        ...state,
        ...payload,
      };
    }

    // case TOGGLE_TERMS_CONFIRMED: {
    //   return {
    //     ...state,
    //     termsConfirmed: !state.termsConfirmed
    //   };
    // }

    // case SIGNIN_ATTEMPT:
    // case SIGNUP_ATTEMPT:
    //   return {
    //     ...state,
    //     isAuthenticating: true,
    //     signUpErrorMessage: '',
    //     signInErrorMessage: ''
    //   };

    // case SIGNUP_SUCCESS:
    //   setAuthToken(payload.token);
    //   saveUserCredentialsInSaveStorage(state.email, state.password);
    //   return {
    //     ...state,
    //     token: payload.token,
    //     isAuthenticated: true,
    //     newUser: true,
    //     isAuthenticating: false
    //   };

    // case SIGNIN_SUCCESS:
    //   setAuthToken(payload.token);
    //   return {
    //     ...state,
    //     token: payload.token,
    //     isAuthenticated: true,
    //     isAuthenticating: false
    //   };

    // case SIGNIN_FAIL:
    // case SIGN_OUT_SUCCESS:
    // case SIGNUP_FAIL:
    //   clearAuthToken();
    //   return {
    //     ...state,
    //     ...payload,
    //     isAuthenticated: false,
    //     token: null,
    //     user: null,
    //     newUser: false,
    //     isAuthenticating: false
    //   };

    default:
      return state;
  }
};
