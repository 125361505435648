import { defaultAction } from './../utils/types';

export type SessionState = {
  userId: string;
  authenticating: boolean;
};

export const initialState = {
  userId: null,
  authenticating: false,
};

export default (state = initialState, { type, payload }: defaultAction) => {
  switch (type) {
    case 'SIGN_IN_2FA_ATTEMPT':
    case 'RECOVER_SESSION_ATTEMPT':
      return {
        ...state,
        authenticating: true,
      };

    case 'SIGN_IN_2FA_SUCCESS':
    case 'RECOVER_SESSION_SUCCESS':
      return {
        ...state,
        authenticating: false,
        userId: payload,
      };

    case 'SIGN_IN_2FA_FAIL':
    case 'RECOVER_SESSION_FAIL':
      return {
        ...state,
        authenticating: false,
      };

    case 'LOGOUT':
      return {
        ...state,
        userId: null,
      };

    default:
      return state;
  }
};
