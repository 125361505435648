import * as React from 'react';
import colors from '../../colors';
import Overlay from './Overlay';

export interface ApproveTransactionModalProps {
  transaction: any;
  contract: any;
  onClose: () => void;
  onApprove: () => void;
}

export default function ApproveTransactionModal(
  props: ApproveTransactionModalProps
) {
  return (
    <Overlay onClose={() => props.onClose()}>
      <>
        <div className='approve-transaction-modal'>
          <div className='approve-transaction-modal__title'>
            Confirm transaction settelment
          </div>
          <div className='approve-transaction-modal__content'>
            Are you sure you want to settle this transaction?
            <br />
            <br />
            <div className='approve-transaction-modal__content__details'>
              <div className='approve-transaction-modal__content__details__label'>
                Amount
              </div>
              <div className='approve-transaction-modal__content__details__value'>
                {props.transaction.amount}
              </div>
            </div>
            <div className='approve-transaction-modal__content__details'>
              <div className='approve-transaction-modal__content__details__label'>
                From
              </div>
              <div className='approve-transaction-modal__content__details__value'>
                {props.contract.customerEmail}
              </div>
            </div>
            <div className='approve-transaction-modal__content__details'>
              <div className='approve-transaction-modal__content__details__label'>
                To
              </div>
              <div className='approve-transaction-modal__content__details__value'>
                Quidlock
              </div>
            </div>
            <div className='approve-transaction-modal__content__details'>
              <div className='approve-transaction-modal__content__details__label'>
                Created at
              </div>
              <div className='approve-transaction-modal__content__details__value'>
                {props.transaction?.createdAt
                  ? new Date(props.transaction.createdAt).toLocaleString()
                  : ''}
              </div>
            </div>
            <div className='approve-transaction-modal__content__details'>
              <div className='approve-transaction-modal__content__details__label'>
                Status
              </div>
              <div className='approve-transaction-modal__content__details__value'>
                {props.transaction.status}
              </div>
            </div>
            <div className='approve-transaction-modal__content__details'>
              <div className='approve-transaction-modal__content__details__label'>
                Method
              </div>
              <div className='approve-transaction-modal__content__details__value'>
                {props.transaction.paymentMethod}
              </div>
            </div>
            <div className='approve-transaction-modal__content__buttons'>
              <button
                className='approve-transaction-modal__content__buttons__button'
                onClick={() => props.onClose()}
                style={{ marginRight: '16px' }}>
                Cancel
              </button>
              <button
                className='approve-transaction-modal__content__buttons__button'
                style={{ backgroundColor: colors.primary }}
                onClick={() => props.onApprove()}>
                Approve
              </button>
            </div>
          </div>
        </div>

        <style>{`
        .approve-transaction-modal {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
        .approve-transaction-modal__title {
          font-size: 24px;
          font-weight: bold;
          color: #333;
          margin-bottom: 16px;
        }
        .approve-transaction-modal__content {
          font-size: 16px;
          color: #333;
          margin-bottom: 16px;
        }
        .approve-transaction-modal__content__buttons {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 16px;
        }
        .approve-transaction-modal__content__buttons__button {
          padding: 8px 16px;
          border-radius: 5px;
          border: none;
          background-color: #333;
          color: white;
          font-size: 16px;
          cursor: pointer;
        }

        .approve-transaction-modal__content__details {
          display: flex;
          justify-content: space-between;
          margin-top: 8px;
        }
        .approve-transaction-modal__content__details__label {
          font-weight: bold;
        }
        .approve-transaction-modal__content__details__value {
          color: #333;
        }

      `}</style>
      </>
    </Overlay>
  );
}
