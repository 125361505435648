import * as React from 'react';

export interface CloseButtonProps {
  onClick: () => void;
}

export default function CloseButton(props: CloseButtonProps) {
  return (
    <>
      <div onClick={() => props.onClick()} className='close-button'>
        <svg
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M18 6L6 18'
            stroke='black'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M6 6L18 18'
            stroke='black'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      </div>
      <style>{`
        .close-button {
          z-index: 300;
          position: absolute;
          top: 20px;
          right: 20px;
          cursor: pointer;
        }
      `}</style>
    </>
  );
}
