import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import colors from '../../colors';

interface Props {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Search: React.FC<Props> = ({ value, onChange }) => {
  const [showClear, setShowClear] = useState(false);

  const handleClear = () => {
    setShowClear(false);
    onChange({
      target: {
        value: '',
      },
    } as React.ChangeEvent<HTMLInputElement>);
  };

  return (
    <>
      <div className='search-container'>
        {value.length <= 0 && (
          <FontAwesomeIcon
            className='search-icon'
            size='1x'
            color={colors.primary}
            icon={faSearch}
          />
        )}
        <input
          className='search-input'
          type='text'
          placeholder='Search'
          value={value}
          onChange={(e) => {
            setShowClear(e.target.value.length > 0);
            onChange(e);
          }}
        />
        <i className='clear-icon fas fa-times' onClick={handleClear}>
          x
        </i>
      </div>
      <style>
        {`
        .search-container {
          position: relative;
          height: 52px;
          width: 260px;
          padding-bottom: 16px;
        }
        .search-icon {
          position: absolute;
          right: 10px;
          top: 35%;
          transform: translateY(-50%);
        }
        .search-input {
          width: 100%;
          height: 100%;
          padding-left: 10px;
          border-radius: 4px;
          
        }
        .clear-icon {
          position: absolute;
          right: 16px;
          top: 35%;
          transform: translateY(-50%);
          cursor: pointer;
          visibility: ${showClear ? 'visible' : 'hidden'};
        }
        `}
      </style>
    </>
  );
};

export default Search;
