import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Spinner, Table } from 'reactstrap';
import TableTitle from '../molecues/TableTitle';
import { GenericObject, StoreState } from '../../utils/types';
import {
  getWithdrawsAction,
  setWithdrawsKeyValue,
  // setWithdrawsKeyValue,
} from '../../actions/withdrawsActions';
import { formatAmount, unCamelCase } from '../../utils/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { hiddenColumns, statusWithTransfer } from '../../utils/constants';

import { setLayoutKeyValue } from '../../actions/layoutActions';

export interface withdrawsProps {
  setWithdrawsKeyValue: Function;
  setLayoutKeyValue: Function;
  withdraws: Array<any>;
}

class Withdraws extends Component<withdrawsProps, any> {
  onRowClick = (widthdraw: any) => {
    //if status is pending, then show confirm modal
    widthdraw.status === 'pending' &&
      this.props.setLayoutKeyValue('modal', 'withdrawalConfirm');

    this.props.setWithdrawsKeyValue('displayedWithdrawal', widthdraw);
  };

  renderCell = (key: string, value: any) => {
    if (key === 'amount') {
      return <td>${formatAmount(value)}</td>;
    } else if (key === 'createdAt' || key === 'updatedAt') {
      return <td>{new Date(value).toLocaleString()}</td>;
    }

    return <td>{value as string}</td>;
  };

  render() {
    const { withdraws } = this.props;

    return (
      <Fragment>
        <TableTitle title='Withdraws'></TableTitle>
        {Object.keys(withdraws).length > 0 ? (
          <Table celled>
            <thead>
              <tr>
                {Object.keys(withdraws[0])
                  .filter((key) => !hiddenColumns.includes(key))
                  .map((key) => (
                    <th>{unCamelCase(key)}</th>
                  ))}
              </tr>
            </thead>

            <tbody>
              {withdraws.map((withdraw, index) => (
                <Fragment>
                  <tr
                    className='row-hover'
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={() => this.onRowClick(withdraw)}>
                    {Object.entries(withdraw)
                      .filter(([key, value]) => !hiddenColumns.includes(key))
                      .map(([key, value]) => {
                        return this.renderCell(key, value);
                      })}
                  </tr>
                </Fragment>
              ))}
            </tbody>
          </Table>
        ) : (
          <div className='w-100 d-flex p-4'>
            <Spinner size='lg' color='secondary' type='grow' />
          </div>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = ({ withdraws }: StoreState) => ({
  withdraws: withdraws.withdraws,
});

export default connect(mapStateToProps, {
  setWithdrawsKeyValue,
  setLayoutKeyValue,
})(Withdraws);
