import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/js/src/collapse.js';
import RouterComponent from './components/RouterComponent';
import { Provider } from 'react-redux';
import store from './store';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <Provider store={store}>
      <RouterComponent />
      <ToastContainer autoClose={5000} hideProgressBar={true} closeOnClick />
    </Provider>
  );
}

export default App;
