import {
  logout,
  recoverSession,
  signIn2FA,
  signInInit,
} from '../utils/requests';
import history from '../utils/history';
import { setAuthValue } from './authActions';
import routes from '../utils/routes';

export const signInInitAction = (email: string, password: string) => (
  dispatch: Function
) => {
  dispatch({ type: 'SIGN_IN_INIT_ATTEMPT' });

  return signInInit(email, password)
    .then((res) => {
      dispatch({
        type: 'SIGN_IN_INIT_SUCCESS',
        payload: res.data,
      });

      dispatch(setAuthValue('signInStep', 1));
    })
    .catch((error) => {
      dispatch({
        type: 'SIGN_IN_INIT_FAIL',
      });
    });
};

export const signIn2FAAction = (token: string, email: string) => (
  dispatch: Function
) => {
  dispatch({ type: 'SIGN_IN_2FA_ATTEMPT' });

  return signIn2FA(token, email)
    .then((res) => {
      dispatch({
        type: 'SIGN_IN_2FA_SUCCESS',
        payload: res.data,
      });

      dispatch(setAuthValue('signInStep', 0));
    })
    .catch((error) => {
      dispatch({
        type: 'SIGN_IN_2FA_FAIL',
      });
      dispatch(setAuthValue('signInStep', 0));
    });
};

export const recoverSessionAction = () => (dispatch: Function) => {
  dispatch({ type: 'RECOVER_SESSION_ATTEMPT' });

  return recoverSession()
    .then((res) => {
      dispatch({
        type: 'RECOVER_SESSION_SUCCESS',
        payload: res.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: 'RECOVER_SESSION_FAIL',
      });
    });
};

export const logoutAction = () => (dispatch: Function) => {
  dispatch({ type: 'LOGOUT_ATTEMPT' });

  return logout()
    .then((res) => {
      dispatch({
        type: 'LOGOUT_SUCCESS',
        payload: res.data,
      });

      dispatch({ type: 'RESET_APP_STATE' });
      history.push(routes.signIn);
    })
    .catch((error) => {
      dispatch({
        type: 'LOGOUT_FAIL',
      });
    });
};

// export const getSessionAction = (userType: string) => (dispatch: Function) => {
//   dispatch({ type: 'GET_ALL_USERS_ATTEMPT' });

//   return getSession()
//     .then((res) => {
//       dispatch({
//         type: 'GET_ALL_USERS_SUCCESS',
//         payload: res.data,
//       });

//       console.log(res.data);
//     })
//     .catch((error) => {
//       dispatch({
//         type: 'GET_ALL_USERS_FAIL',
//       });
//     });
// };

// ...
// if (response.ok) {
//     return dispatch(receiveCurrentUser(data));
//   }
//   return dispatch(receiveErrors(data));
// };
// ...
// if (response.ok) {
//     return dispatch(receiveCurrentUser(data));
//   }
//   return dispatch(receiveErrors(data));
// };
// ...
// if (response.ok) {
//     return dispatch(logoutCurrentUser());
//   }
//   return dispatch(receiveErrors(data));
// };
