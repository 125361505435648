import * as React from 'react';
import CloseButton from './CloseButton';

export interface OverlayProps {
  onClose: () => void;
  children: React.ReactNode;
}

export default function Overlay(props: OverlayProps) {
  return (
    <>
      <div className='overlay'>
        <div className='form-wrapper'>
          <CloseButton onClick={props.onClose} />
          {props.children}
        </div>
      </div>
      <style>{`
        .overlay {
          z-index: 200;
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.5);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
        .form-wrapper {
          position: relative;
          z-index: 200;
          background-color: white;
          padding: 60px 100px;
          border-radius: 5px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
        //display different on mobile devices
        @media (max-width: 600px) {
          .form-wrapper {
            padding: 60px 20px;
            width: 94%;
          }
        }
      `}</style>
    </>
  );
}
