import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Spinner, Table } from 'reactstrap';
import TableTitle from '../molecues/TableTitle';
import { StoreState } from '../../utils/types';
import { getUsersAction } from '../../actions/usersActions';
import { unCamelCase } from '../../utils/helpers';
import {
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import colors from '../../colors';
import Search from '../molecues/Search';

export interface UsersProps {
  getUsersAction: Function;
  users: Array<any>;
}

class Users extends Component<UsersProps, any> {
  state = {
    search: '',
  };
  componentDidMount() {
    this.props.getUsersAction();
  }

  updateSearch = (e: any) => {
    this.setState({ search: e.target.value });
  };

  filterBySearch = (users: Array<any>) => {
    const { search } = this.state;

    if (search === '') {
      return users;
    }

    return users.filter(
      (user: any) =>
        user.firstName?.toLowerCase().includes(search.toLowerCase()) ||
        user.lastName?.toLowerCase().includes(search.toLowerCase()) ||
        user.email?.toLowerCase().includes(search.toLowerCase()) ||
        user.phone?.toLowerCase().includes(search.toLowerCase())
    );
  };

  renderCell = (key: string, value: any) => {
    if (key === 'phoneVerified') {
      return (
        <td>
          {value === true && (
            <FontAwesomeIcon
              size='1x'
              color={colors.green}
              icon={faCheckCircle}
            />
          )}
        </td>
      );
    }

    return <td>{value as string}</td>;
  };

  render() {
    const { users } = this.props;

    return (
      <Fragment>
        <TableTitle title='Users'></TableTitle>
        <Search
          value={this.state.search}
          // placeholder='Search by name, email, phone number'
          onChange={this.updateSearch}
        />
        {Object.keys(users).length > 0 ? (
          <Table celled>
            <thead>
              <tr>
                {Object.keys(users[0])
                  .filter((key) => key !== '_id')
                  .map((key) => (
                    <th>{unCamelCase(key)}</th>
                  ))}
              </tr>
            </thead>

            <tbody>
              {this.filterBySearch(users).map((user) => (
                <tr>
                  {Object.entries(user)
                    .filter(([key, value]) => key !== '_id')
                    .map(([key, value]) => this.renderCell(key, value))}
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <div className='w-100 d-flex p-4'>
            <Spinner size='lg' color='secondary' type='grow' />
          </div>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = ({ users }: StoreState) => ({
  users: users.users,
});

export default connect(mapStateToProps, { getUsersAction })(Users);
