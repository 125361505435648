import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { StoreState } from '../../utils/types';
import StatsCard from '../molecues/StatsCard';

export interface HomeProps {
  contracts: Array<any>;
  users: Array<any>;
  totalActiveContractsValue: number;
}

class Home extends Component<HomeProps, any> {
  componentDidMount() {}

  render() {
    return (
      <Fragment>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
            gridGap: '24px',
            padding: '36px',
          }}>
          <StatsCard
            title={'Total $ value locked in Quidlock'}
            value={`$${this.props.totalActiveContractsValue}`}
          />
          <StatsCard
            title={'Total users registered'}
            value={`${this.props.users.length}`}
          />
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ users, contracts }: StoreState) => ({
  contracts: contracts.contracts,
  totalActiveContractsValue: contracts.totalActiveContractsValue,
  users: users.users,
});

export default connect(mapStateToProps, {})(Home);
