import React, { Component, Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { Spinner, Table } from 'reactstrap';
import TableTitle from '../molecues/TableTitle';
import { GenericObject, StoreState, TContract } from '../../utils/types';
import {
  getContractsAction,
  getTransferInAction,
  getTransferOutAction,
  setDisplayedContract,
} from '../../actions/contractsActions';
import { unCamelCase } from '../../utils/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { hiddenColumns, statusWithTransfer } from '../../utils/constants';
import { contractStatusLabel } from '../../utils/texts';
import history from '../../utils/history';
import Search from '../molecues/Search';

export interface contractsProps {
  setDisplayedContract: Function;
  getTransferInAction: Function;
  getTransferOutAction: Function;
  contracts: Array<any>;
}

class Contracts extends Component<contractsProps, any> {
  state = {
    search: '',
  };

  updateSearch = (e: any) => {
    this.setState({ search: e.target.value });
  };

  filterBySearch = (contracts: Array<TContract>) => {
    const { search } = this.state;

    if (search === '') {
      return contracts;
    }

    return contracts.filter(
      (contract: any) =>
        contract.title?.toLowerCase().includes(search.toLowerCase()) ||
        contract.status?.toLowerCase().includes(search.toLowerCase()) ||
        contract.amount
          ?.toString()
          .toLowerCase()
          .includes(search.toLowerCase()) ||
        contract.initiator?.toLowerCase().includes(search.toLowerCase()) ||
        contract.sellerEmail?.toLowerCase().includes(search.toLowerCase()) ||
        contract.customerEmail?.toLowerCase().includes(search.toLowerCase()) ||
        contract.customerPhone?.toLowerCase().includes(search.toLowerCase()) ||
        contract.sellerPhone?.toLowerCase().includes(search.toLowerCase()) ||
        contract.participantEmail
          ?.toLowerCase()
          .includes(search.toLowerCase()) ||
        contract.participantPhone?.toLowerCase().includes(search.toLowerCase())
    );
  };

  sortByStatus = (contracts: Array<TContract>) => {
    return contracts.sort((a: TContract, b: TContract) => {
      if (a.status === b.status) {
        return 0;
      }
      if (a.status === 'payment_initiated') {
        return -1;
      }
      if (b.status === 'payment_initiated') {
        return 1;
      }
      if (a.status === 'payment_rejected') {
        return -1;
      }
      if (b.status === 'payment_rejected') {
        return 1;
      }
      if (a.status === 'payment_approved') {
        return -1;
      }
      if (b.status === 'payment_approved') {
        return 1;
      }
      if (a.status === 'service_complete') {
        return -1;
      }
      if (b.status === 'service_complete') {
        return 1;
      }
      if (a.status === 'accept') {
        return -1;
      }
      if (b.status === 'accept') {
        return 1;
      }
      if (a.status === 'reject') {
        return -1;
      }
      if (b.status === 'reject') {
        return 1;
      }
      if (a.status === 'complete') {
        return -1;
      }
      if (b.status === 'complete') {
        return 1;
      }
      if (a.status === 'cancel') {
        return -1;
      }
      if (b.status === 'cancel') {
        return 1;
      }
      return 0;
    });
  };

  onRowClick = (contract: any) => {
    console.log(contract);
    history.push(`/contract/${contract.id}`);
    this.props.setDisplayedContract(contract);

    switch (contract.status) {
      case 'payment_initiated':
      case 'payment_rejected':
      case 'payment_approved':
        this.props.getTransferInAction(contract.inPaymentId);
        break;

      case 'service_complete':
      case 'accept':
      case 'reject':
      case 'complete':
      case 'cancel':
        this.props.getTransferInAction(contract.inPaymentId);
        this.props.getTransferOutAction(contract.outPaymentId);
        break;

      default:
        break;
    }
  };

  renderCell = (key: string, value: any) => {
    if (key === 'amount') {
      return <td>${value as string}</td>;
    } else if (key === 'status') {
      return <td>{(contractStatusLabel as GenericObject)[value]}</td>;
    }

    return <td>{value as string}</td>;
  };

  render() {
    const { contracts } = this.props;

    return (
      <Fragment>
        <TableTitle title='Contracts'></TableTitle>
        <Search value={this.state.search} onChange={this.updateSearch} />
        {Object.keys(contracts).length > 0 ? (
          <Table celled>
            <thead>
              <tr>
                {Object.keys(contracts[0])
                  .filter((key) => !hiddenColumns.includes(key))
                  .map((key) => (
                    <th>{unCamelCase(key)}</th>
                  ))}
                <th>Transactions</th>
              </tr>
            </thead>

            <tbody>
              {this.sortByStatus(this.filterBySearch(contracts)).map(
                (contract, index) => (
                  <Fragment>
                    <tr
                      className='row-hover'
                      style={{
                        cursor: 'pointer',
                      }}
                      onClick={() => this.onRowClick(contract)}
                      data-toggle={
                        statusWithTransfer.includes(contract.status) &&
                        'collapse'
                      }
                      data-target={`.multi-collapse-${index}`}
                      aria-controls={`multiCollapseExample-${index}`}>
                      {Object.entries(contract)
                        .filter(
                          ([key, value]) =>
                            ![
                              '_id',
                              'id',
                              'inPaymentId',
                              'outPaymentId',
                            ].includes(key)
                        )
                        .map(([key, value]) => {
                          return this.renderCell(key, value);
                        })}
                      {/* <td className='text-center'>
                      {statusWithTransfer.includes(contract.status) && (
                        <FontAwesomeIcon
                          icon={faChevronDown}
                          style={{ zIndex: 10 }}
                        />
                      )}
                    </td> */}
                    </tr>
                    <tr
                      className={`collapse multi-collapse-${index}`}
                      id={`multiCollapseExample-${index}`}>
                      <div className='w-100 p-4 border-top'>
                        <span className='w-100'>Transfer In</span>
                      </div>
                    </tr>
                  </Fragment>
                )
              )}
            </tbody>
          </Table>
        ) : (
          <div className='w-100 d-flex p-4'>
            <Spinner size='lg' color='secondary' type='grow' />
          </div>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = ({ contracts }: StoreState) => ({
  contracts: contracts.contracts,
});

export default connect(mapStateToProps, {
  setDisplayedContract,
  getTransferInAction,
  getTransferOutAction,
})(Contracts);
