import * as React from 'react';
import Logo from '../atoms/Logo';

import routes from '../../utils/routes';
import { Link } from 'react-router-dom';
import colors from '../../colors';
import { connect } from 'react-redux';
import { StoreState } from '../../utils/types';
import { logoutAction } from '../../actions/sessionActions';

export interface SideMenuProps {
  logoutAction: Function;
}

const SideMenu = (props: SideMenuProps) => {
  const renderMenuItem = (to: string, label: string) => {
    return (
      <div className='py-2'>
        <Link to={to} style={styles.link}>
          {/* <Icon name='home' /> */}
          {label}
        </Link>
      </div>
    );
  };

  return (
    <div
      style={{
        backgroundColor: colors.primary,
      }}
      className='h-100 w-100 px-4 py-5 d-flex flex-column justify-content-between'>
      <div>
        <Logo></Logo>
        {renderMenuItem(routes.home, 'Overview')}
        {renderMenuItem(routes.users, 'Users')}
        {renderMenuItem(routes.contracts, 'Contracts')}
        {renderMenuItem(routes.withdraws, 'Withdraws')}
      </div>
      <div>
        {renderMenuItem(routes.archived, 'Archived')}
        <span onClick={() => props.logoutAction()} style={styles.link}>
          Logout
        </span>
      </div>
    </div>
  );
};

const mapStateToProps = ({ auth }: StoreState) => ({});

export default connect(mapStateToProps, {
  logoutAction,
})(SideMenu);

const styles = {
  link: {
    color: colors.white,
    fontFamily: 'Kufam',
    fontWeight: 600,
    fontSize: 24,
    cursor: 'pointer',
  },
};
